import * as R from 'ramda'
import * as urql from 'urql'
import * as React from 'react'
import gql from 'graphql-tag'

const getLiveCasinoTables = gql`
  query {
    liveCasinoTables {
      backgroundUrl
      backgroundOverlayUrl
      backgroundOverlayImageAlignment
      backgroundColor
      betBehind
      colorMode
      dealerName
      gameType
      id
      imageUrl
      maxBetCents
      minBetCents
      seatsAvailable
      seatsTotal
      tableOpen
      tags
      title
      titleUrl
      provider
    }
  }
`

function isTableOpen(game) {
  return game.tableOpen === true
}

function hasSeatsAvailable(game) {
  return game.seatsAvailable !== 0
}

function isOfGameType(category) {
  return game =>
    category === 'all' ||
    R.includes(category, game.tags) ||
    game.gameType === category
}

export function useLiveCasinoTablesQuery(category) {
  const [response] = urql.useQuery({ query: getLiveCasinoTables })
  const [allGames, setAllGames] = React.useState()
  const [filtredGames, setGames] = React.useState([])

  React.useEffect(() => {
    if (response.data) {
      setAllGames(response.data.liveCasinoTables)
    }
  }, [response.data])

  React.useEffect(() => {
    setGames(
      R.filter(
        R.allPass([isTableOpen, hasSeatsAvailable, isOfGameType(category)]),
        R.values(allGames)
      )
    )
  }, [category, allGames])

  return [
    {
      data: allGames || response.data,
      error: response.error,
      fetching: response.fetching,
    },
    filtredGames,
  ]
}
