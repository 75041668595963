import * as ReactRedux from 'react-redux'
import * as React from 'react'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from './configuration'

const Image = styled.img`
  ${props =>
    css({
      height: props.height,
      width: 'auto',
    })}
`

export function PaymentProviderImages(props) {
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  return (
    <Common.Box
      alignItems="center"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
    >
      {paymentProviders.map(item => (
        <LazyLoad once width="141px" height="34px" key={item.name} offset={100}>
          <Common.Box m={1}>
            <Image
              height={props.height}
              width="141px"
              alt={item.name}
              m={props.margin}
              src={item.image.url}
            />
          </Common.Box>
        </LazyLoad>
      ))}
    </Common.Box>
  )
}

PaymentProviderImages.defaultProps = {
  margin: 2,
}

PaymentProviderImages.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.string,
}
