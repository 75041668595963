import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Urql from 'urql'
import gql from 'graphql-tag'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as Configuration from './configuration'
import * as Icons from './icons'
import { Button } from './button'
import { HtmlContent } from './html-content'
import { LiveCasinoGameDisplay } from './live-casino-game-display'
import { PaymentProviderImages } from './payment-provider-images'
import { SportsbookDirect } from './sports-direct'
import { useTranslationImgproxyUrl } from './use-imgproxy-url'

const landingPageAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
   opacity: 1;
  }
`

const sportsbookDirectEnabled = Configuration.createFeatureSelector(
  'sportsbookDirect'
)

// TODO add to theme as a variant if necessary
const uppercase = {
  name: 'uppercase',
  value: {
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
  },
}

const Background = styled.div`
  background-color: static-black;
  background-repeat: no-repeat;

  animation-name: ${landingPageAnimation};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  ${props =>
    css({
      backgroundImage: props.backgroundImg,
      backgroundPosition: props.backgroundPosition,
      backgroundSize: props.backgroundSize,
    })}
`
// The children styling here is a special case to target bold text used in
// the HTMLContent, which should be a different color to the normal p text.
const StyledHtmlContent = styled.div`
  ${css({
    fontSize: [6, 7],
    maxWidth: '350px',
    mb: 1,
    lineHeight: '1',
    fontWeight: 700,
    textAlign: ['center', 'left'],
    color: 'white',
    textTransform: 'uppercase',
    px: [3, '0px'],
  })}

  & b, & strong {
    ${css({
      color: 'white',
    })}
  }
`

// TODO make resizing a bit less hacky
const imgTransforms = [
  {
    resizingType: 'fill',
    resizingWidth: '800',
    resizingHeight: '700',
  },
  {
    resizingType: 'fit',
    gravity: 'ce',
    resizingWidth: '1000',
    resizingHeight: '700',
  },
]

const StyledHeader = styled.div`
  @supports (-webkit-background-clip: text) or (background-clip: text) {
    background: linear-gradient(275.69deg, #009739 9.04%, #fedd00 99.98%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: cover;
    text-shadow: 0px 0px #00000000;
  }
  text-shadow: 2px 2px 0px #000000;
  ${css({
    textTransform: 'uppercase',
    display: 'inline',
    fontSize: ['48px', '84px'],
    fontWeight: '700',
    lineHeight: ['55px', '80px'],
    fontFamily: 'head',
    textShadow: 3,
    maxWidth: ['300px', '500px'],
    mb: 0,
    textAlign: ['center', 'left'],
  })}
`

function BannerArea() {
  const i18n = I18n.useI18n()
  const sportsImage = useTranslationImgproxyUrl(
    i18n.translate(`landing-page.sports.image`),
    imgTransforms
  )

  return (
    <Common.Box mb={1} mx={[null, null, 1, 2]}>
      <Background
        backgroundImg={[
          `url(/images/amuletobet-triangle-fade-purple.svg), linear-gradient(0deg, #000000 30%, rgba(0, 0, 0, 0) 50%),
            url(${sportsImage[0]})`,
          `url(/images/amuletobet-triangle-fade-purple.svg), linear-gradient(90deg, #000000 40%, rgba(0, 0, 0, 0) 60%),
            url(${sportsImage[1]})`,
        ]}
        backgroundPosition={[
          '300px bottom, center top, center top',
          '230px bottom, left, center right',
        ]}
        backgroundSize={['515px', 'auto, auto, contain']}
      >
        <Common.Box
          mx={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems={['center', 'flex-start']}
          height={['530px', null, null]}
        >
          <StyledHeader>
            {i18n.translate(`landing-page.sports.heading`)}
          </StyledHeader>
          <Common.Box
            display="flex"
            flexDirection="column"
            alignItems={['center', 'flex-start']}
            maxWidth="500px"
            width="100%"
            overflow="hidden"
          >
            <StyledHtmlContent>
              <HtmlContent
                html={{
                  __html: i18n.translate(`landing-page.sports.cta-content`),
                }}
              />
            </StyledHtmlContent>
            <Common.Box
              mb={0}
              display="flex"
              width={['100%', 'unset']}
              justifyContent={['center', 'flex-start']}
            >
              <ReactRouter.Link to="?register=me">
                <Button
                  variant="primary"
                  fontSize={[1, 2]}
                  textTransform="uppercase"
                  letterSpacing="0.05em"
                  style={{
                    border: '2px solid transparent',
                    borderImageSource:
                      'linear-gradient(244.17deg, #009739 30.21%, #FEDD00 102.44%)',
                    borderImageSlice: 1,
                    webkitMask: 'linear-gradient(#fff 0 0)',
                    mask: 'linear-gradient(#fff 0 0)',
                    borderRadius: '4px',
                  }}
                >
                  {i18n.translate('landing-page.register')}
                </Button>
              </ReactRouter.Link>
            </Common.Box>
            <ReactRouter.Link to="?login=me">
              <Common.Box
                textAlign={['center', 'left']}
                pt={0}
                pb={4}
                fontSize={[3, 4]}
                lineHeight="1"
                style={{
                  cursor: 'pointer',
                  'text-decoration': 'underline',
                }}
              >
                {i18n.translate('landing-page.login-link')}
              </Common.Box>
            </ReactRouter.Link>
          </Common.Box>
        </Common.Box>
      </Background>
    </Common.Box>
  )
}

const IconContainer = styled.div`
  position: absolute;
  top: 40%;
  bottom: 50%;
  right: 5%;

  ${css({
    fontSize: 5,
  })}
`

const SellingPointCard = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  min-width: 240px;
  max-width: 430px;

  ${props =>
    css({
      background: `${props.backgroundColor}, url(${props.backgroundSrc})`,
      backgroundBlendMode: 'multiply',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 100%',
      backgroundPosition: 'center right',
      p: 1,
      flex: 1,
      maxWidth: ['430px', '400px', '380px'],
    })}

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -6%;

    height: 100%;
    transform: skew(-18deg, 0deg);

    ${props =>
      css({
        background: props.backgroundOverlayColor,
        width: ['75%', '80%', '70%', '68%'],
      })}
  }
`
const getCasinoCategories = gql`
  query SubMenuCategoriesQuery {
    catalog(key: "main") {
      ... on GetCatalogSuccess {
        categories {
          key
          id
        }
      }
      ... on GetCatalogFailure {
        error
        errorCode
      }
    }
  }
`


function SellingPoints() {
  const i18n = I18n.useI18n()
  const history = ReactRouter.useHistory()

  const [response] = Urql.useQuery({ query: getCasinoCategories })

  const sellingPointImage1 = useTranslationImgproxyUrl(
    i18n.translate(`landing-page.selling-point-1.image`)
  )

  const sellingPointImage2 = useTranslationImgproxyUrl(
    i18n.translate(`landing-page.selling-point-2.image`)
  )

  const sellingPointImage3 = useTranslationImgproxyUrl(
    i18n.translate(`landing-page.selling-point-3.image`)
  )

  const sellingPointImage4 = useTranslationImgproxyUrl(
    i18n.translate(`landing-page.selling-point-4.image`)
  )

  const bingo = response?.data?.catalog?.categories?.find(
    el => el.key === 'bingo'
  )

  return (
    <Common.Box
      display="flex"
      flexWrap="wrap"
      gridGap={0}
      mx={[1, null, null, 2]}
    >
      <SellingPointCard
        onClick={() => history.push('/casino')}
        backgroundOverlayColor="linear-gradient(90deg, #36331D 41.61%, #8A7C00 100%)"
        backgroundSrc={sellingPointImage1}
        backgroundColor="linear-gradient(0deg, rgba(254, 221, 0, 0.7), rgba(254, 221, 0, 0.7))"
      >
        <IconContainer>
          <Icons.OpenCardIcon />
        </IconContainer>
        <Common.Box
          style={uppercase.value}
          fontWeight="bold"
          lineHeight="1.2"
          fontSize={3}
          maxWidth="200px"
          color="static-white"
          position="relative"
          zIndex="99"
        >
          {i18n.translate(`landing-page.selling-point-1.title`)}
        </Common.Box>

        <Common.Box
          fontSize={3}
          color="rgba(255, 255, 255, 0.8)"
          pt={0}
          lineHeight="1.2"
          position="relative"
          zIndex="99"
        >
          {i18n.translate(`landing-page.selling-point-1.content`)}
        </Common.Box>
      </SellingPointCard>
      <SellingPointCard
        onClick={() => history.push('/sports')}
        backgroundOverlayColor="linear-gradient(90deg, #183021 41.61%, #115129 100%)"
        backgroundColor="linear-gradient(0deg, rgba(31, 180, 55, 0.7), rgba(31, 180, 55, 0.7))"
        backgroundSrc={sellingPointImage2}
      >
        <IconContainer>
          <Icons.OpenCardIcon />
        </IconContainer>
        <Common.Box
          style={uppercase.value}
          fontWeight="bold"
          lineHeight="1.2"
          fontSize={3}
          maxWidth="200px"
          color="static-white"
          position="relative"
          zIndex="99"
        >
          {i18n.translate(`landing-page.selling-point-2.title`)}
        </Common.Box>

        <Common.Box
          fontSize={3}
          color="rgba(255, 255, 255, 0.8)"
          pt={0}
          lineHeight="1.2"
          zIndex="99"
          position="relative"
        >
          {i18n.translate(`landing-page.selling-point-2.content`)}
        </Common.Box>
      </SellingPointCard>
      <SellingPointCard
        onClick={() =>
          history.push(bingo ? `/casino/${bingo.id}/${bingo.key}` : '/casino')
        }
        backgroundOverlayColor="linear-gradient(90deg, #1B2335 41.61%, #1D366C 100%)"
        backgroundColor="linear-gradient(0deg, rgba(117, 160, 255, 0.7), rgba(117, 160, 255, 0.7))"
        backgroundSrc={sellingPointImage3}
      >
        <IconContainer>
          <Icons.OpenCardIcon />
        </IconContainer>
        <Common.Box
          style={uppercase.value}
          fontWeight="bold"
          lineHeight="1.2"
          fontSize={3}
          maxWidth="200px"
          color="static-white"
          position="relative"
          zIndex="99"
        >
          {i18n.translate(`landing-page.selling-point-3.title`)}
        </Common.Box>

        <Common.Box
          fontSize={3}
          color="rgba(255, 255, 255, 0.8)"
          pt={0}
          lineHeight="1.2"
          zIndex="99"
          position="relative"
        >
          {i18n.translate(`landing-page.selling-point-3.content`)}
        </Common.Box>
      </SellingPointCard>
      <SellingPointCard
        onClick={() => history.push('/live-casino/discover')}
        backgroundOverlayColor="linear-gradient(90deg, #33002B 41.61%, #6B005B 100%)"
        backgroundColor="linear-gradient(0deg, rgba(247, 124, 229, 0.7), rgba(247, 124, 229, 0.7))"
        backgroundSrc={sellingPointImage4}
      >
        <IconContainer>
          <Icons.OpenCardIcon />
        </IconContainer>
        <Common.Box
          style={uppercase.value}
          fontWeight="bold"
          lineHeight="1.2"
          fontSize={3}
          maxWidth="200px"
          color="static-white"
          position="relative"
          zIndex="99"
        >
          {i18n.translate(`landing-page.selling-point-4.title`)}
        </Common.Box>

        <Common.Box
          fontSize={3}
          color="rgba(255, 255, 255, 0.8)"
          pt={0}
          lineHeight="1.2"
          zIndex="99"
          position="relative"
        >
          {i18n.translate(`landing-page.selling-point-4.content`)}
        </Common.Box>
      </SellingPointCard>
    </Common.Box>
  )
}

export function LandingPage() {
  const i18n = I18n.useI18n()

  const showSportsbookDirect = ReactRedux.useSelector(state =>
    sportsbookDirectEnabled(state.configuration)
  )

  return (
    <Common.Box
      display="flex"
      flexDirection="column"
      maxWidth={['100%', null, null, '1440px']}
      mx="auto"
    >
      <Common.Box>
        <BannerArea />
        <SellingPoints />
        <Common.Box mt={[1, 2]} mx={[1, null, null, 2]} opacity="0.8">
          <PaymentProviderImages margin={1} height="24px" />
        </Common.Box>
      </Common.Box>
      <Common.Box mt={3}>
        <Common.Box pb={[2, 4]} pt={[3, 1]}>
          <LiveCasinoGameDisplay />
        </Common.Box>
        {showSportsbookDirect && (
          <Common.Box px={2} pb={[2, 4]} pt={[3, 1]}>
            <Common.Box
              pb={1}
              style={{ textTransform: 'uppercase' }}
              fontWeight="bold"
              fontFamily="head"
              fontSize={6}
            >
              {i18n.translate('sportsbook-section.title')}
            </Common.Box>
            <SportsbookDirect
              widgetName="promo"
              widgetPlaceholder="operator_page"
              minFrameHeight={180}
            />
          </Common.Box>
        )}
        <Common.Box p={2} textAlign="left" fontSize={2}>
          <HtmlContent
            html={{
              __html: i18n.translate('landing-page.seo-content'),
            }}
          />
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

// for @loadable/components
export default LandingPage
